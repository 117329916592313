import React from 'react';
import { AppBar, Toolbar, Button, Menu, MenuItem, IconButton } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from './firebase';
import { useUser } from './UserContext';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import xhbtLogo from './xhbt-black.png'; // Import the image

const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useUser(); // Get user from context

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    auth.signOut()
      .then(() => {
        console.log("User signed out");
        navigate('/');
      })
      .catch((error) => {
        console.error("Sign out error", error);
      });
    handleClose();
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Link to="/" style={{ flexGrow: 1 }}>
          <img src={xhbtLogo} alt="XHBT Logo" style={{ height: '40px' }} /> {/* Use the image */}
        </Link>
        {user ? (
          isMobile ? (
            <div>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem component={Link} to="/faq" onClick={handleClose}>FAQ</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
          ) : (
            <>
              <Button component={Link} to="/faq" color="inherit">FAQ</Button>
              <Button onClick={handleLogout} color="inherit">Logout</Button>
            </>
          )
        ) : (
          <Button component={Link} to="/faq" color="inherit">FAQ</Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;