import React from 'react';
import { Container, Typography } from '@mui/material';

const FAQ = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Frequently Asked Questions
      </Typography>

      <Typography variant="h6" gutterBottom>
        What is XHBT?
      </Typography>
      <Typography variant="body1" paragraph>
        XHBT is a way for artists to generate revenue from their art outside of selling artwork or streaming (for music artists).
      </Typography>

      <Typography variant="h6" gutterBottom>
        How do I create an artist profile on XHBT?
      </Typography>
      <Typography variant="body1" paragraph>
        Artists must sign up using the XHBT app. After signing up, you can create a profile with an image, artist name, artist description, and optionally include an external portfolio link and upload a demo reel.
      </Typography>

      <Typography variant="h6" gutterBottom>
        What kind of content is prohibited on XHBT?
      </Typography>
      <Typography variant="body1" paragraph>
        Because the Internet can be what it is, we cannot allow any levels of nudity or sexually suggestive content.
      </Typography>

      <Typography variant="h6" gutterBottom>
        How can I delete my account?
      </Typography>
      <Typography variant="body1" paragraph>
        You can either delete your account in the app under your profile or email xhbt@xhbt.org and we'll do it for you.
      </Typography>

      <Typography variant="h6" gutterBottom>
        How does the commission process work?
      </Typography>
      <Typography variant="body1" paragraph>
        Patrons select an artist's profile and hit the "Commission Me" button. They will fill out a form, and the artist will be directly notified via email to review the commission request and respond within a few days. XHBT will facilitate the communication between the patron and the artist.
      </Typography>

      <Typography variant="h6" gutterBottom>
        What are the fees for using XHBT?
      </Typography>
      <Typography variant="body1" paragraph>
        XHBT will take a 10% commission fee from the patron once a commission is completed.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Can I use XHBT to get commissions outside the platform?
      </Typography>
      <Typography variant="body1" paragraph>
        Yes, you are not required to use XHBT to get commissions. You can use XHBT as an artist portfolio tool and take commissions off of the XHBT platform using your portfolio link.
      </Typography>

      <Typography variant="h6" gutterBottom>
        How is the commission contract handled?
      </Typography>
      <Typography variant="body1" paragraph>
        XHBT will work with the patron to create the contract. Once created, XHBT will come to the artist and get approval. Signing happens via a digital link. XHBT will escrow the account funds ahead of time (exclusions apply for larger commissions) and once the commission is complete and delivery is made, XHBT will release the funds to the artist.
      </Typography>

      <Typography variant="h6" gutterBottom>
        What kind of artists are you looking for?
      </Typography>
      <Typography variant="body1" paragraph>
        Professional artists who are actively commissioning new art. We use the term artist here to encompass visual, audio, and literary art.
      </Typography>

      <Typography variant="h6" gutterBottom>
        How can I ensure my profile stands out?
      </Typography>
      <Typography variant="body1" paragraph>
        XHBT is not intended to be a discovery platform for artists. We are primarily a tool to help facilitate the business end of commissions so you can focus on your art.
      </Typography>

      <Typography variant="h6" gutterBottom>
        What happens if an artist violates the content guidelines?
      </Typography>
      <Typography variant="body1" paragraph>
        If an artist violates content guidelines, they will be removed from the platform. If they have an active commission, we will attempt to complete the commission with the artist first and mute their profile from future commissions.
      </Typography>

      <Typography variant="h6" gutterBottom>
        How can I contact support for issues or inquiries?
      </Typography>
      <Typography variant="body1" paragraph>
        For support or inquiries, please email us at xhbt@xhbt.org.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Is there any support for artists in marketing their work?
      </Typography>
      <Typography variant="body1" paragraph>
        We are not a marketing platform; we are a commissioning platform for existing artists. However, we intend on actively pursuing organizations that have commissioned artists in the past and featuring artists from our platform to those organizations for future commissions.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Can I link my social media accounts to my profile?
      </Typography>
      <Typography variant="body1" paragraph>
        We provide a single external link that you can link either a social media account or a composite link sharing profile.
      </Typography>

      <Typography variant="h6" gutterBottom>
        How do I know if my commission request has been accepted?
      </Typography>
      <Typography variant="body1" paragraph>
        XHBT will be in contact with both artists and patrons throughout the commission creation phase and will communicate with you throughout.
      </Typography>
    </Container>
  );
};

export default FAQ;