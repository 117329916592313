import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { getFirestore, doc, getDoc, getDocs, collection, query, where } from 'firebase/firestore';

class ArtistServices {
  static fetchProfilePhoto = async (artist) => {
    try {
      const storage = getStorage();
      const tmpURL = `profilePhotos/${artist.userId}/profile.jpg`;
      const storageRef = ref(storage, tmpURL);
      const url = await getDownloadURL(storageRef);
      artist.profilePhoto = url;
      return url;
    } catch (error) {
      console.error("Error fetching photo URL:", error);
      artist.profilePhoto = null;
      return null;
    }
  };

  static fetchVideoUrl = async (content) => {
    if (content.videoUrl) {
      return content.videoUrl;
    }

    try {
      const storage = getStorage();
      const videoPath = `userVideos/${content.artistID}/xhbt.mov`;
      const storageRef = ref(storage, videoPath);
      const url = await getDownloadURL(storageRef);
      content.videoUrl = url;
      return url;
    } catch (error) {
      console.error(`Failed to fetch video URL for artist ${content.artistID}:`, error);
      return null;
    }
  };

  static fetchVideoImageUrl = async (content) => {
    try {
      const storage = getStorage();
      const imagePath = `userVideos/${content.artistID}/xhbt.png`;
      const storageRef = ref(storage, imagePath);
      const url = await getDownloadURL(storageRef);
      return url;
    } catch (error) {
      console.error(`Failed to fetch video image URL for artist ${content.artistID}:`, error);
      return null;
    }
  };

  static fetchUserProfile = async (userId) => {
    try {
      const db = getFirestore();
      const docRef = doc(db, "profiles", userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return { userId, ...docSnap.data() };
      } else {
        console.log("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
      return null;
    }
  };

  static getArtists = async () => {
    try {
      const db = getFirestore();
      const q = query(collection(db, "profiles"), where("isArtist", "==", true));
      const querySnapshot = await getDocs(q);
      const artists = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const artist = { userId: doc.id, ...doc.data() };
          await ArtistServices.fetchProfilePhoto(artist);
          return artist;
        })
      );

      // Sort artists and deprioritize those whose names start with "artist"
      const sortedArtists = artists.sort((a, b) => {
        const aName = a.username.toLowerCase();
        const bName = b.username.toLowerCase();
        const aStartsWithArtist = aName.startsWith("artist");
        const bStartsWithArtist = bName.startsWith("artist");

        if (aStartsWithArtist && !bStartsWithArtist) {
          return 1;
        } else if (!aStartsWithArtist && bStartsWithArtist) {
          return -1;
        } else {
          return aName.localeCompare(bName);
        }
      });

      return sortedArtists;
    } catch (error) {
      console.error("Error fetching artists:", error);
      return [];
    }
  };
}

export default ArtistServices;