import React, { useState, useEffect } from 'react';
import { updateProfile } from 'firebase/auth';
import { storage } from './firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useUser } from './UserContext';
import ProfileServices from './profileServices';
import { Box, Button, TextField, Typography, CircularProgress } from '@mui/material';

const ProfileEditor = () => {
  const { user } = useUser();
  console.log('user on profile is ', user);
  const [username, setUsername] = useState(user?.username || '');
  const [photoURL, setPhotoURL] = useState(user?.photoURL || '');
  const [artistDescription, setArtistDescription] = useState('');
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    const fetchProfile = async () => {
      const profileData = await ProfileServices.fetchUserProfile(user.uid);
      if (profileData) {
        setArtistDescription(profileData.artistDescription || '');
        setUsername(profileData.username || '');
        setPhotoURL(profileData.profilePhotoURL || '');
      }
    };
    fetchProfile();
  }, [user.uid]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateProfile(user, { username, photoURL });
      const profileData = { username, photoURL, artistDescription };
      const success = await ProfileServices.updateUserProfile(user.uid, profileData);
      if (success) {
        alert('Profile updated successfully');
      } else {
        alert('Error updating profile');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Error updating profile');
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploading(true);
    const storageRef = ref(storage, `profilePhotos/${user.uid}/${file.name}`);
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    setPhotoURL(url);
    setUploading(false);
  };

  return (
    <>
      <Box
        p={3}
        maxWidth="600px"
        mx="auto"
        bgcolor="background.paper"
        borderRadius={2}
        boxShadow={3}
        textAlign="center"
      >
        <img src={photoURL} alt="Profile" style={{ width: '100px', height: '100px', marginTop: '10px', borderRadius: '50%' }} />
        <Box mb={2}>
          <Button variant="contained" color="primary" component="label">
            Change Image
            <input type="file" hidden onChange={handleImageUpload} />
          </Button>
        </Box>
        {uploading && <CircularProgress />}
        <form onSubmit={handleSubmit}>
          <Box mb={2}>
            <TextField
              label="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              fullWidth
              variant="outlined"
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Artist Description"
              value={artistDescription}
              onChange={(e) => setArtistDescription(e.target.value)}
              fullWidth
              multiline
              rows={4}
              variant="outlined"
            />
          </Box>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Update Profile
          </Button>
        </form>
      </Box>
      <Box mt={2} display="flex" justifyContent="center" alignItems="center">
        <Typography variant="caption">
          {user.uid}
        </Typography>
      </Box>
    </>
  );
};

export default ProfileEditor;
