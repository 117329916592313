import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from './UserContext';
import { firestore } from './firebase';
import { collection, addDoc } from 'firebase/firestore';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Card,
  CardContent,
  CircularProgress,
  CardMedia,
  Grid,
} from '@mui/material';
import ArtistServices from './artistServices';
import defaultProfileImage from './xhbt-white.png';

const CommissionForm = () => {
  const { artistId } = useParams();
  const { user } = useUser();
  const [budget, setBudget] = useState('');
  const [timeframe, setTimeframe] = useState('');
  const [description, setDescription] = useState('');
  const [performanceDetails, setPerformanceDetails] = useState('');
  const [message, setMessage] = useState('');
  const [artist, setArtist] = useState(null);

  useEffect(() => {
    const fetchArtistInfo = async () => {
      const fetchedArtist = await ArtistServices.fetchUserProfile(artistId);
      const profilePhoto = await ArtistServices.fetchProfilePhoto(fetchedArtist);
      fetchedArtist.profilePhoto = profilePhoto;
      setArtist(fetchedArtist);
    };

    fetchArtistInfo();
  }, [artistId]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await addDoc(collection(firestore, 'commissions'), {
        artistId,
        userId: user.uid,
        budget,
        timeframe,
        description,
        performanceDetails,
        createdAt: new Date(),
      });
      setMessage('Commission request sent successfully. You can check your email and expect a response from the artist within a few days.');
    } catch (error) {
      setMessage('Error sending commission request');
      console.error(error);
    }
  };

  if (!artist) return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <CircularProgress />
    </Box>
  );

  return (
    <Container>
      <Card>
        <CardContent>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={8} textAlign="center">
              <Typography variant="h4" gutterBottom>Commission {artist.username}</Typography>
              <CardMedia
                component="img"
                height="auto"
                image={artist.profilePhoto || defaultProfileImage}
                alt="Artist"
                style={{ borderRadius: "8px", maxWidth: "200px", margin: "0 auto" }}
              />
            </Grid>
          </Grid>
          <Typography variant="body1" mt={2}>
            Please fill out the following form to request a commission. Once submitted, the artist will review your request and contact you within a few days.
          </Typography>
          <form onSubmit={handleSubmit}>
            <Box mt={2}>
              <TextField
                label="Budget"
                type="number"
                value={budget}
                onChange={(e) => setBudget(e.target.value)}
                fullWidth
                required
              />
            </Box>
            <Box mt={2}>
              <TextField
                label="Timeframe"
                type="text"
                value={timeframe}
                onChange={(e) => setTimeframe(e.target.value)}
                fullWidth
                required
              />
            </Box>
            <Box mt={2}>
              <TextField
                label="Description"
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                multiline
                rows={4}
                required
              />
            </Box>
            <Box mt={2}>
              <TextField
                label="Performance/Exhibition Details"
                type="text"
                value={performanceDetails}
                onChange={(e) => setPerformanceDetails(e.target.value)}
                fullWidth
                multiline
                rows={4}
                required
              />
            </Box>
            <Box mt={2}>
              <Button type="submit" variant="contained" color="primary" >
                Submit
              </Button>
            </Box>
          </form>
          {message && (
            <Typography variant="body1" mt={2} color="success.main">
              {message}
            </Typography>
          )}
        </CardContent>
      </Card>
    </Container>
  );
};

export default CommissionForm;