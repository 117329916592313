// Footer.js
import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const Footer = () => {
  return (
    <Box 
      component="footer" 
      sx={{ 
        p: 2, 
        mt: 'auto', 
        backgroundColor: 'clear', 
        textAlign: 'center' 
      }}
    >
      <Typography variant="body2" color="textSecondary">
        &copy; {new Date().getFullYear()} Principal LA, LLC. All rights reserved. XHBT is a registered trademark.
      </Typography>
      <Typography variant="body2">
        <Link href="/terms" color="inherit" style={{ marginRight: '20px' }}>Terms and Services</Link>
        <Link href="/privacy" color="inherit">Privacy</Link>
      </Typography>
    </Box>
  );
};

export default Footer;