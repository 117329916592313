import React from 'react';
import { Card, CardContent, Typography, CardMedia, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import defaultProfileImage from './xhbt-black.png';

const ArtistCard = ({ artist }) => {
  return (
    <Card sx={{ maxWidth: 345, margin: 2 }}>
      <CardMedia
        component="img"
        height="140"
        image={artist.profilePhoto || defaultProfileImage}
        alt={artist.username}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {artist.username}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {artist.artistDescription}
        </Typography>
      </CardContent>
      <Box display="flex" justifyContent="center" pb={2}>
        <Button component={Link} to={`/artist/${artist.userId}`} variant="contained" color="primary">
          View Portfolio
        </Button>
      </Box>
    </Card>
  );
};

export default ArtistCard;