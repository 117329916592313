// PrivacyPolicy.js
import React from 'react';
import { Container, Typography } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Privacy Policy
      </Typography>
      
      <Typography variant="body1" paragraph>
        Welcome to XHBT. This Privacy Policy outlines how we collect, use, and protect your information when you use our platform.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        Analytics and Tracking
      </Typography>
      <Typography variant="body1" paragraph>
        We run some analytics using Google and have crash logging in our app. We may add more tracking later to enhance user experience and improve our services.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        Account Deletion
      </Typography>
      <Typography variant="body1" paragraph>
        People who have signed up for an account can go to the app to delete their account and content. All account information will be permanently removed upon deletion.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        Messaging Retention
      </Typography>
      <Typography variant="body1" paragraph>
        We may retain messaging between XHBT and artists for an indefinite amount of time to ensure quality and address any disputes or concerns that may arise.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        General Terms
      </Typography>
      <Typography variant="body1" paragraph>
        We reserve the right to update this Privacy Policy as necessary. Please review it regularly to stay informed about how we protect your information.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Contact Us
      </Typography>
      <Typography variant="body1" paragraph>
        For concerns or comments, please contact us by email at xhbt@xhbt.org.
      </Typography>
    </Container>
  );
};

export default PrivacyPolicy;