import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Container, Typography, CircularProgress, Card, CardContent, CardMedia } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import ArtistServices from './artistServices';
import defaultProfileImage from './xhbt-white.png';

const ArtistPortfolioView = () => {
  const { artistId } = useParams();
  const [artist, setArtist] = useState(null);
  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchArtistInfo = async () => {
      const fetchedArtist = await ArtistServices.fetchUserProfile(artistId);
      const profilePhoto = await ArtistServices.fetchProfilePhoto(fetchedArtist);
      fetchedArtist.profilePhoto = profilePhoto;
      setArtist(fetchedArtist);

      const content = { artistID: artistId, videoUrl: null };
      const thumbnailUrl = await ArtistServices.fetchVideoImageUrl(content);
      const videoUrl = await ArtistServices.fetchVideoUrl(content);
      setVideoThumbnail(thumbnailUrl);
      setVideoUrl(videoUrl);
    };

    fetchArtistInfo();
  }, [artistId]);

  const playVideo = () => {
    setIsPlaying(true);
  };

  const viewPortfolio = () => {
    if (artist && artist.portfolioURL) {
      window.open(artist.portfolioURL, "_blank");
    } else {
      alert("This artist has not yet provided a portfolio. Please check back later.");
    }
  };

  const startCommission = () => {
    navigate(`/commission/${artistId}`, { state: { from: `/artist/${artistId}` } });
  };

  if (!artist) return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <CircularProgress />
    </Box>
  );

  return (
    <Container>
      <Card>
        <CardContent>
          <Box mt={2} display="flex" justifyContent="center">
            <CardMedia
              component="img"
              height="auto"
              image={artist.profilePhoto || defaultProfileImage}
              alt="Artist"
              style={{ borderRadius: "8px", maxWidth: '300px' }}
            />
          </Box>
          <Typography variant="h4" textAlign="center" mt={2}>{artist.username}</Typography>
          <Box mt={2} justifyContent="center">
            <Typography  align="center" variant="body1">{artist.artistDescription}</Typography>
          </Box>
          <Box mt={2} display="flex" justifyContent="center" gap={2}>
            <Button variant="contained" onClick={viewPortfolio}>View Portfolio</Button>
            <Button variant="contained" onClick={startCommission}>Commission</Button>
          </Box>
          {videoThumbnail && !isPlaying && (
            <Box mt={2} display="flex" justifyContent="center" position="relative">
              <img
                src={videoThumbnail}
                alt="Video Thumbnail"
                style={{ width: "100%", borderRadius: "8px", cursor: "pointer", maxWidth: '800px' }}
                onClick={playVideo}
              />
              <PlayCircleOutlineIcon
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  fontSize: '4rem',
                  color: 'white',
                }}
                onClick={playVideo}
              />
            </Box>
          )}
          {isPlaying && (
            <Box mt={2} display="flex" justifyContent="center">
              <video width="100%" controls style={{ borderRadius: "8px", maxWidth: '800px' }}>
                <source src={videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </Box>
          )}
        </CardContent>
      </Card>
    </Container>
  );
};

export default ArtistPortfolioView;