import React, { useEffect, useState } from 'react';
import { Container, Grid, CircularProgress } from '@mui/material';
import ArtistCard from './ArtistCard';
import ArtistServices from './artistServices';

const ArtistCollection = () => {
  const [artists, setArtists] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    ArtistServices.getArtists().then((artistsData) => {
      setArtists(artistsData);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container>
      <Grid container spacing={3}>
        {artists.map((artist) => (
          <Grid item key={artist.userId} xs={12} sm={6} md={4}>
            <ArtistCard artist={artist} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ArtistCollection;