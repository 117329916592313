// TermsOfService.js
import React from 'react';
import { Container, Typography } from '@mui/material';

const TermsOfService = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Terms of Service
      </Typography>
      
      <Typography variant="body1" paragraph>
        Welcome to XHBT, the platform for new art. By using our platform, you agree to the following terms and conditions.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        Account Deletion
      </Typography>
      <Typography variant="body1" paragraph>
        You can delete your account at any time using the app. All account information and content will be permanently removed upon deletion.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        Prohibited Content
      </Typography>
      <Typography variant="body1" paragraph>
        We do not allow any hate speech, nudity, or sexually suggestive material of any kind on the platform. Any content found in violation of this policy will be removed, and the account may be suspended or banned.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        Commissions
      </Typography>
      <Typography variant="body1" paragraph>
        All commissioned art must be delivered in a physical medium such as a painting or performance. XHBT may charge a brokerage fee on all commissions. By listing on the XHBT platform, you are not required to use XHBT to be commissioned. We only provide the service to make it easier for the artist and have them focus on their work. Our fee on commissions changes but is generally around 10%.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        General Terms
      </Typography>
      <Typography variant="body1" paragraph>
        By using XHBT, you agree to comply with all applicable laws and regulations. We reserve the right to modify these terms at any time, so please review them regularly.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Removals
      </Typography>
      <Typography variant="body1" paragraph>
        We reserve the right to remove any profile off the app at any time for infractions, perceived or otherwise.
      </Typography>

    <Typography variant="h6" gutterBottom>
      Content
    </Typography>
    <Typography variant="body1" paragraph>
      XHBT is not responsible for the content or actions of its users. By using our platform, you agree to indemnify and hold XHBT and its affiliates, officers, agents, and employees harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your use of our services.
    </Typography>

      <Typography variant="h6" gutterBottom>
        Contact Us
      </Typography>
      <Typography variant="body1" paragraph>
        For concerns or comments, please contact us by email at xhbt@xhbt.org.
      </Typography>
    </Container>
  );
};

export default TermsOfService;