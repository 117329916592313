import React, { useEffect, useRef } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { UserProvider, useUser } from './UserContext';
import Header from './Header';
import Footer from './Footer';
import ProfileEditor from './ProfileEditor';
import Login from './Login';
import Home from './Home';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './Privacy';
import ArtistPortfolioView from './ArtistPortfolioView';
import FAQ from './FAQ';
import { httpsCallable } from 'firebase/functions';
import { functions } from './firebase';
import { lightTheme, darkTheme } from './theme';
import CommissionForm from './CommissionForm';

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useUser();
  const location = useLocation();

  if (loading) return <div>Loading...</div>;

  if (!user) {
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }

  return children;
};

const sendRouteChangeToSlack = async (prevRoute, newRoute) => {
  if (process.env.NODE_ENV === 'development') {
    return
  }
  
  const sendSlackMessage = httpsCallable(functions, 'sendSlackMessage');
  await sendSlackMessage({ text: `Route changed from ${prevRoute} to ${newRoute}` });
};

const AppContent = () => {
  const location = useLocation();
  const previousRoute = useRef(location.pathname);
  const initialLoad = useRef(true);

  useEffect(() => {
    const sendPageLoad = async () => {
      await sendRouteChangeToSlack('N/A', location.pathname);
      previousRoute.current = location.pathname;
    };

    const sendRouteChange = async () => {
      if (previousRoute.current !== location.pathname) {
        await sendRouteChangeToSlack(previousRoute.current, location.pathname);
        previousRoute.current = location.pathname;
      }
    };

    if (initialLoad.current) {
      sendPageLoad();
      initialLoad.current = false;
    } else {
      sendRouteChange();
    }
  }, [location]);

  const isHomePage = location.pathname === '/';
  
  const currentTheme = isHomePage ? darkTheme : lightTheme;

  return (
    <ThemeProvider theme={currentTheme}>
      <CssBaseline />
      {!isHomePage && <Header />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/artist/:artistId" element={<ArtistPortfolioView />} />
        <Route path="/commission/:artistId" element={<ProtectedRoute><CommissionForm /></ProtectedRoute>} />
        <Route path="/profile" element={<ProtectedRoute><ProfileEditor /></ProtectedRoute>} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/faq" element={<FAQ />} />
      </Routes>
      <Footer />
    </ThemeProvider>
  );
};

const App = () => {
  return (
    <UserProvider>
      <AppContent />
    </UserProvider>
  );
};

export default App;