import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';

class ProfileServices {
  static fetchProfilePhoto = async (userId) => {
    try {
      const storage = getStorage();
      const tmpURL = `profilePhotos/${userId}/profile.jpg`;
      const storageRef = ref(storage, tmpURL);
      const url = await getDownloadURL(storageRef);
      return url;
    } catch (error) {
      console.error("Error fetching photo URL:", error);
      return null;
    }
  };

  static fetchUserProfile = async (userId) => {
    try {
      const db = getFirestore();
      const docRef = doc(db, "profiles", userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return { userId, ...docSnap.data() };
      } else {
        console.log("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
      return null;
    }
  };

  static updateUserProfile = async (userId, profileData) => {
    try {
      const db = getFirestore();
      await updateDoc(doc(db, "profiles", userId), profileData);
      return true;
    } catch (error) {
      console.error("Error updating user profile:", error);
      return false;
    }
  };
}

export default ProfileServices;