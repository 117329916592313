import React from 'react';
import { Container, Typography, Box } from '@mui/material';
// import { collection, getDocs, query, where, addDoc } from 'firebase/firestore';
// import { functions, firestore } from './firebase';
import logo from './xhbt-white.png'; // Import the image
// import debounce from 'lodash.debounce'; // Import lodash.debounce
// import { httpsCallable } from 'firebase/functions';
import ArtistCollection from './ArtistCollection';

const Home = () => {
  // const sendSlackNotification = async (message) => {
  //   if (process.env.NODE_ENV === 'development') {
  //     return
  //   }
  //   const sendSlackMessage = httpsCallable(functions, 'sendSlackMessage');
  //   await sendSlackMessage({ text: message });
  // };

  return (
    <Container>
      <Box textAlign="center" mt={4}>
        <img src={logo} alt="XHBT Logo" style={{ width: '150px', marginBottom: '20px' }} />
        <Typography variant="h1" align="center" gutterBottom>
          COMMISSION MORE ART
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          XHBT® enables professional visual, musical, and literary artists to receive paid commissions from institutions and patrons worldwide. 
        </Typography>
        <Box mt={4}>
          <ArtistCollection />
        </Box>
      </Box>
    </Container>
  );
};

export default Home;