import React, { useEffect } from 'react';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Container, Typography, Box } from '@mui/material';

const Login = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from ?? "/";

  const uiConfig = {
    signInFlow: 'popup',
    signInOptions: [
      GoogleAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      signInSuccessWithAuthResult: (authResult) => {
        console.log('Successfully signed in:', authResult);
        navigate(from);
        return false; 
      },
      signInFailure: (error) => {
        console.error('Sign in failed:', error);
      }
    },
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(
      (user) => {
        if (user) {
          navigate(from);
        }
      },
      (error) => {
        console.error('Auth state change error:', error);
      }
    );

    return () => unsubscribe();
  }, [auth, navigate, from]);

  return (
    <Container>
      <Box textAlign="center" mt={4}>
        <Typography variant="h1">Patron Login</Typography>
        <Typography variant="body1" mt={2}>
          Please sign in to commission new art.
        </Typography>
      </Box>
      <Box mt={4}>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
      </Box>
      <Box mt={2} textAlign="center">
        <Typography variant="body2">
          By signing in, you agree to our <Link to="/terms" style={{ color: '#d11e98' }}>Terms and Services</Link>.
        </Typography>
      </Box>
    </Container>
  );
};

export default Login;